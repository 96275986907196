import { zodResolver } from "@hookform/resolvers/zod";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, RadioButtonContainer } from "../../../../../layout/FormLayout";
import {
  removeUnderscore,
  toTitleCase,
  useFormWrapper,
  useStoreState,
} from "../../../../../util/util";
import { TextField } from "../../../../../components/TextFields/TextFields";
import { Controller } from "react-hook-form";
import { SelectBoxV2 } from "../../../../../components/SelectBoxV2/SelectBoxV2";
import { PrimaryButtonFitContainer } from "../../../../../components/Buttons/Buttons";
import type {
  DataMutate,
  FilterLabel,
  StorefrontFilterSetting,
  TranslationsSchema,
} from "../../../../../types/types";
import type {
  AttributeObjectType,
  AttributeSchema,
  ShortListSchema,
} from "../../../../../types/types.PIM";
import useSWR from "swr";
import { endpoints } from "../../../../../endpoints";
import type { AxiosError } from "axios";
import Axios from "axios";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { TextAreaCounter } from "../../../../../components/TextAreaCounter/TextAreaCounter";
import { SectionTitle } from "../../../../../components/Form/Form";
import type { FormValues } from "./CreateAttribute";
import {
  CreateAttributeSchemaFn,
  listToOptions,
  MarginBottomH6,
  MarginBottomHeaderLeft,
} from "./CreateAttribute";
import { DeletableChips } from "../../../../../components/DeletableChips/DeletableChips";
import { ToggleSwitchV2 } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import noop from "lodash/noop";
import type { AttributeObjectFilterType } from "../../SellerAdminSettings/PortfolioControlsTab/util";
import { mapAttributeObjectToFilterType } from "../../SellerAdminSettings/PortfolioControlsTab/util";
import { RadioButton } from "../../../../../components/RadioButton/RadioButton";
import styled from "styled-components";
import { match } from "ts-pattern";

const PlaceHolder = ({ width = "800px", height = "800px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="120" height="120" fill="#EFF1F3" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.2503 38.4816C33.2603 37.0472 34.4199 35.8864 35.8543 35.875H83.1463C84.5848 35.875 85.7503 37.0431 85.7503 38.4816V80.5184C85.7403 81.9528 84.5807 83.1136 83.1463 83.125H35.8543C34.4158 83.1236 33.2503 81.957 33.2503 80.5184V38.4816ZM80.5006 41.1251H38.5006V77.8751L62.8921 53.4783C63.9172 52.4536 65.5788 52.4536 66.6039 53.4783L80.5006 67.4013V41.1251ZM43.75 51.6249C43.75 54.5244 46.1005 56.8749 49 56.8749C51.8995 56.8749 54.25 54.5244 54.25 51.6249C54.25 48.7254 51.8995 46.3749 49 46.3749C46.1005 46.3749 43.75 48.7254 43.75 51.6249Z"
      fill="#687787"
    />
  </svg>
);

const TwoColumnLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

const RadioButtonCell = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const PlaceholderCell = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const EditAttribute = ({
  attribute,
  refreshAttribute,
  onComplete,
  list,
}: {
  attribute: AttributeSchema;
  refreshAttribute: DataMutate<
    AttributeSchema & { translations: TranslationsSchema[] }
  >;
  onComplete: () => void;
  list: ShortListSchema | undefined;
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [productReferenceType, setProductReferenceType] = useState<string>(
    attribute.is_multiple_choice ? "multi" : "single"
  );
  const {
    storefront_id,
    storefront_metadata: { default_language, tenant_id },
  } = useStoreState();
  const { data: typesResponse } = useSWR<{ data: string[] }>(
    endpoints.v2_pim_types()
  );
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(CreateAttributeSchemaFn(t)),
    defaultValues: {
      name: attribute.name,
      display_name: attribute?.display_name ?? undefined,
      description: attribute?.description ?? undefined,
      input_type: {
        label: toTitleCase(removeUnderscore(attribute.input_type)),
        value: attribute.input_type,
      },
      list_id: {
        label: toTitleCase(list?.parent?.name ?? list?.name ?? ""),
        value: attribute.list_id,
      },
      ...match(attribute.input_type)
        .with("single_select", "multi_select", () => ({
          icons_enabled: attribute.icons_enabled ?? false,
          size: attribute.size ?? "medium",
        }))
        .otherwise(() => ({
          icons_enabled: false,
          size: "medium" as "medium" | "large" | "small",
        })),
    },
  });
  const { handleSubmit, register, formState, control, errors, watch } =
    methodsOfUseForm;

  const inputValue = watch("input_type");

  const showList = () =>
    (inputValue.value === "single_select" ||
      inputValue.value === "multi_select") &&
    list &&
    attribute.list_id;

  const isAttributeObjectFilterType = (objectType: AttributeObjectType) => {
    try {
      return Boolean(
        mapAttributeObjectToFilterType(objectType as AttributeObjectFilterType)
      );
    } catch (error) {
      return false;
    }
  };

  const updateFilterLabels = async (newLabel: string) => {
    const filterType = mapAttributeObjectToFilterType(
      attribute.object_type as AttributeObjectFilterType
    );
    const storefrontFilterSetting = await Axios.get<StorefrontFilterSetting[]>(
      endpoints.v1_storefronts_id_products_filters_settings(storefront_id)
    ).then(({ data }) =>
      data.find((setting) => setting.filter_type === filterType)
    );
    if (storefrontFilterSetting) {
      const { is_active, is_searchable } = storefrontFilterSetting;
      const updatedFilterLabels: FilterLabel[] =
        storefrontFilterSetting.filter_labels.map(({ label, language }) =>
          language !== default_language
            ? { label: "", language }
            : { label: newLabel, language }
        );
      await Axios.patch(
        endpoints.v1_storefronts_id_products_filters_settings_filterType(
          storefront_id,
          filterType
        ),
        {
          is_active,
          is_searchable,
          filter_labels: updatedFilterLabels,
          is_visible: true,
        }
      );
    }
  };

  const changeProductReferenceType = (
    e: React.FormEvent<HTMLSelectElement>
  ) => {
    if (e.currentTarget.value) {
      setProductReferenceType(e.currentTarget.value);
    }
  };

  useEffect(() => {
    register("icons_enabled");
    register("size");
  }, [register]);

  const onSubmit = async (
    formValues: FormValues & { icons_enabled: boolean; size: string }
  ): Promise<void> => {
    setSubmitting(true);
    const { display_name, description } = formValues;
    const trimmedDisplayName = display_name?.trim();

    try {
      const updateData = match(attribute.input_type)
        .with("single_select", "multi_select", () => ({
          display_name: trimmedDisplayName,
          description,
          icons_enabled: formValues.icons_enabled,
          size: formValues.size,
        }))
        .with("product_reference", () => ({
          display_name: trimmedDisplayName,
          description,
          is_multiple_choice: productReferenceType === "multi",
        }))
        .otherwise(() => ({
          display_name: trimmedDisplayName,
          description,
        }));

      await Axios.patch(
        endpoints.v2_tenants_id_pim_attributes_id(tenant_id!!, attribute.id),
        updateData
      );

      if (display_name && isAttributeObjectFilterType(attribute.object_type)) {
        await updateFilterLabels(display_name);
        // always re-index after updating filter labels
        await Axios.post(
          endpoints.v1_storefronts_id_or_slug_reindex(storefront_id)
        );
      }
      await refreshAttribute();
      notifySuccess(t("Attribute successfully edited"));
      onComplete();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not edit attribute. Something went wrong."),
        {
          error,
        }
      );
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <MarginBottomHeaderLeft>
        <SectionTitle>{t("Edit Attribute")}</SectionTitle>
      </MarginBottomHeaderLeft>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <MarginBottomH6>{t("Attribute Definition")}</MarginBottomH6>
        <TextField
          name="name"
          label={t("Attribute Name")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
          readOnly
        />
        <TextField
          name="display_name"
          label={t("Display Name")}
          theref={register({
            required: false,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <TextAreaCounter
          name="description"
          label={t("Short Description")}
          theref={register({
            required: false,
          })}
          formState={formState}
          errors={errors}
          watch={watch}
        />
        <MarginBottomH6>{t("Attribute Type")}</MarginBottomH6>
        {typesResponse && (
          <>
            <Controller
              as={SelectBoxV2}
              control={control}
              name="input_type"
              autoComplete="on"
              placeholder={t("Attribute Type")}
              options={typesResponse.data.map((type) => ({
                label: toTitleCase(removeUnderscore(type)),
                value: type,
              }))}
              rules={{
                required: true,
              }}
              errors={errors}
              formState={formState}
              isDisabled={true}
            />
            {inputValue.value === "product_reference" && (
              <RadioButtonContainer>
                <RadioButton
                  name="product_reference_type"
                  value="single"
                  checked={productReferenceType === "single"}
                  optionTitle={t("Single Reference")}
                  handleChange={changeProductReferenceType}
                />
                <RadioButton
                  name="product_reference_type"
                  value="multi"
                  checked={productReferenceType === "multi"}
                  optionTitle={t("Multi Reference")}
                  handleChange={changeProductReferenceType}
                />
              </RadioButtonContainer>
            )}
          </>
        )}
        {showList() && (
          <>
            <Controller
              as={SelectBoxV2}
              control={control}
              name="list_id"
              autoComplete="on"
              placeholder={t("List")}
              options={list ? [listToOptions(list)] : []}
              rules={{
                required: true,
              }}
              errors={errors}
              formState={formState}
              isDisabled={true}
            />
            {!!attribute.choices &&
              attribute.choices.length > 0 &&
              !!list?.parent && (
                <>
                  <ToggleSwitchV2
                    label={t("Use only specific values")}
                    onClick={noop}
                    name="specific_values"
                    defaultChecked={true}
                    disabled
                  />
                  <DeletableChips
                    chips={
                      Array.from(attribute.choices)?.map?.((choice) => ({
                        name: choice,
                        active: false,
                      })) ?? []
                    }
                    noneSelectedName="choices"
                    handleClick={noop}
                  />
                </>
              )}
            <Controller
              name="icons_enabled"
              control={control}
              render={(field) => (
                <ToggleSwitchV2
                  label={t("Enable Icons")}
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
            <Controller
              name="size"
              control={control}
              rules={{ required: watch("icons_enabled") }}
              render={(field) => (
                <>
                  {watch("icons_enabled") && (
                    <TwoColumnLayout>
                      <div>
                        <GridRow>
                          <RadioButtonCell>
                            <RadioButton
                              name="iconSize"
                              value="small"
                              theref={register({ required: true })}
                              optionTitle={t("Small (32 X 32px)")}
                              checked={field.value === "small"}
                              handleChange={() => field.onChange("small")}
                            />
                          </RadioButtonCell>
                          <PlaceholderCell>
                            <PlaceHolder width="32px" height="32px" />
                          </PlaceholderCell>
                        </GridRow>
                        <GridRow>
                          <RadioButtonCell>
                            <RadioButton
                              name="iconSize"
                              value="medium"
                              optionTitle={t("Medium (64 X 64px)")}
                              checked={field.value === "medium"}
                              handleChange={() => field.onChange("medium")}
                            />
                          </RadioButtonCell>
                          <PlaceholderCell>
                            <PlaceHolder width="64px" height="64px" />
                          </PlaceholderCell>
                        </GridRow>
                        <GridRow>
                          <RadioButtonCell>
                            <RadioButton
                              name="iconSize"
                              value="large"
                              optionTitle={t("Large (128 X 128px)")}
                              checked={field.value === "large"}
                              handleChange={() => field.onChange("large")}
                            />
                          </RadioButtonCell>
                          <PlaceholderCell>
                            <PlaceHolder width="128px" height="128px" />
                          </PlaceholderCell>
                        </GridRow>
                      </div>
                    </TwoColumnLayout>
                  )}
                </>
              )}
            />
          </>
        )}
        <PrimaryButtonFitContainer
          style={{ marginTop: "32px" }}
          type="submit"
          loading={submitting}
        >
          {t("Save")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
};
